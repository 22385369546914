import React, { Component, useState, useEffect } from 'react';
import { TextField, Tooltip, Autocomplete, Button, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import InputMask from "react-input-mask";
import iPhoneRus from '../../images/i_phone_rus.png';
import metros from '../../images/metros.json';
import Message from '../modal/message';

const Form = (props) => {
    const [form, setForm] = useState({
        fio: "",
        phone: "",
        email: "",
        coursese: [{id: 1, name: 'Развитие стрессоустойчивости - техники эффективного управления эмоциями в стрессовых ситуациях'}, {id: 2, name: 'Мастер коммуникации - принципы и методы повышения эффективности в ходе повседневного общения, решения деловых вопросов, переговоров, продаж'}, {id: 3, name: 'Магия взаимодействия - типология личности и как правильно строить отношения с людьми'}],
        courses: "",
        city: "",
        metro: "",
        code: '',
        code_repeat: '',
        click_code: false
    })

    const [open, setOpen] = useState(false);
    const isMobile = window.innerWidth <= 1023;
    const [showModalMessage, setShowModalMessage] = useState(false)
    const [modalMessageText, setModalMessageText] = useState("")
    const [modalMessageType, setModalMessageType] = useState(1)
    const [codeActive, setCodeActive] = useState(false);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    function handleChange(event, value) {
        const regEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const name = event.target.name;
        const values = event.target.value;
        const id = event.target.id;
        const files = event.target.files;
        if (name === 'fio') {
            if (values === '') {
                document.getElementById('form-block-' + name).classList.add('error');
                setForm({...form, [name]: values})
            } else {
                document.getElementById('form-block-' + name).classList.remove('error');
                setForm({...form, [name]: values})
            }
        }
        if (name === 'email') {
            if (values === '' || regEmail.test(values) === false) {
                document.getElementById('form-block-' + name).classList.add('error');
                setForm({...form, [name]: values})
            } else {
                document.getElementById('form-block-' + name).classList.remove('error');
                setForm({...form, [name]: values})
            }
        } else if (name === 'phone') {
            if (values === '' || values == '+7(___)___-__-__') {
                document.getElementById('form-block-' + name).classList.add('error');
                setForm({...form, [name]: values})
            } else {
                document.getElementById('form-block-' + name).classList.remove('error');
                setForm({...form, [name]: values})
            }
        } else if (id.includes('form-metro')) {
            if (!value) {
                if (form.metro === '') {
//                    document.getElementById('form-block-courses').classList.add('error');
                    setForm({...form, metro: form.metro})
                } else {
//                    document.getElementById('form-block-courses').classList.remove('error');
                    setForm({...form, metro: form.metro})
                }
            } else {
//                document.getElementById('form-block-courses').classList.remove('error');
                setForm({...form, metro: value})
            }
        } else if (name === 'courses') {
            if (values === '') {
//                document.getElementById('form-block-' + name).classList.add('error');
                setForm({...form, [name]: values})
            } else {
//                document.getElementById('form-block-' + name).classList.remove('error');
                setForm({...form, [name]: values})
            }
        } else if (name === 'city') {
            if (values === '') {
                document.getElementById('form-block-' + name).classList.add('error');
                setForm({...form, [name]: values})
            } else {
                document.getElementById('form-block-' + name).classList.remove('error');
                setForm({...form, [name]: values})
            }
        } else if (name === 'code') {
            if (value === '') {
//                document.getElementById('form-block-' + name).classList.add('error');
                setCodeActive(false)
            } else {
//                document.getElementById('form-block-' + name).classList.remove('error');
                setCodeActive(true)
                setForm({...form, [name]: values})
            }
        }
    }

    function handleSubmit() {
        if (form.fio === "") {
            document.getElementById('form-block-fio').classList.add('error');
        }
        if (form.email === "") {
            document.getElementById('form-block-email').classList.add('error');
        }
        if (form.phone === "") {
            document.getElementById('form-block-phone').classList.add('error');
        }
//        if (form.courses === "") {
//            document.getElementById('form-block-courses').classList.add('error');
//        }
        if (form.city === "") {
            document.getElementById('form-block-city').classList.add('error');
        }
        if (
                !document.getElementById('form-block-fio').classList.contains('error')
                && !document.getElementById('form-block-email').classList.contains('error')
                && !document.getElementById('form-block-phone').classList.contains('error')
//                && !document.getElementById('form-block-courses').classList.contains('error')
                && !document.getElementById('form-block-city').classList.contains('error')
                ) {

            const formData = new FormData();
            formData.append('fio', form.fio)
            formData.append('email', form.email)
            formData.append('phone', form.phone)
            formData.append('courses', form.courses)
            formData.append('city', form.city)
            formData.append('metro', form.metro)

            const responseData = Object.fromEntries(formData.entries());

            fetch(props.urlServer + "/json/form/sms", {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(responseData),
                headers: {
//                    "X-XSRF-TOKEN": document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                return response.json();
            }).then((resp) => {
                if (resp.result === 1) {
                    setForm({...form, click_code: true})
                    setMinutes(0);
                    setSeconds(59);
                } else {

                }
            }, (error) => {

            })
        }
    }

    function handleSubmitSms() {
//        if (form.code === '') {
//            document.getElementById('form-block-code').classList.add('error');
//        }

//        if (!document.getElementById('form-block-code').classList.contains('error')) {

        const formData = new FormData();
        formData.append('fio', form.fio)
        formData.append('email', form.email)
        formData.append('phone', form.phone)
        formData.append('courses', form.courses)
        formData.append('city', form.city)
        formData.append('metro', form.metro)
        formData.append('code', form.code)

        const responseData = Object.fromEntries(formData.entries());

        fetch(props.urlServer + "/json/form/registration", {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(responseData),
            headers: {
//                "X-XSRF-TOKEN": document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
                "Content-Type": "application/json"
            }
        }).then((response) => {
            return response.json();
        }).then((resp) => {
            if (resp.result === 1) {
                setForm({
                    fio: "",
                    phone: "",
                    email: "",
                    coursese: [{id: 1, name: 'Развитие стрессоустойчивости - техники эффективного управления эмоциями в стрессовых ситуациях'}, {id: 2, name: 'Мастер коммуникации - принципы и методы повышения эффективности в ходе повседневного общения, решения деловых вопросов, переговоров, продаж'}, {id: 3, name: 'Магия взаимодействия - типология личности и как правильно строить отношения с людьми'}],
                    courses: "",
                    city: "",
                    metro: "",
                    code: '',
                    code_repeat: '',
                    click_code: false
                })
                setMinutes(0)
                setSeconds(0)
                setCodeActive(false)
                setModalMessageType(1)
                setShowModalMessage(true)
                setModalMessageText(resp.msg)
            } else {
                setModalMessageType(-1)
                setShowModalMessage(true)
                setModalMessageText(resp.msg)
            }
        }, (error) => {

        })
//        }
    }

    function handleCloseModalMessage() {
        if (modalMessageType === 1) {
            setModalMessageText("")
            setShowModalMessage(false)
        } else {
            setModalMessageText("")
            setShowModalMessage(false)
        }
    }

    return (
            <>
            <div className="block-form">
                <h1>Заполните анкету участника промо-акции</h1>
                <h2>Заполнение анкеты не обязывает Вас приобретать продукты банка. <br/>Гарантируем: Ваши данные останутся в безопасности.</h2>
                <div className="block-input" id="form-block-fio">
                    <TextField label="ФИО" variant="standard" id="form-fio" name="fio" value={form.fio} placeholder="Иванов Иван" onChange={handleChange} onClick={handleChange}/>
                </div>
                <div className="block-input" id="form-block-phone">
                    <InputMask mask="+7(999)999-99-99" value={form.phone} onChange={handleChange}>
                        {() => <TextField label="Номер телефона" variant="standard" id="form-phone" name="phone" onClick={handleChange}/>}
                    </InputMask>
                </div>
                <div className="block-input" id="form-block-email">
                    <TextField label="E-mail" variant="standard" id="form-email" name="email" value={form.email} placeholder="somename@somedomain.ru" onChange={handleChange} onClick={handleChange}/>
                </div>
                <div className="block-radio" id="">
                    <label className="block-radio-title">Выберите курс</label>
                    <RadioGroup
                        defaultValue=""
                        name="courses"
                        value={form.courses}
                        onChange={handleChange}>
                        <FormControlLabel value="Развитие стрессоустойчивости - техники эффективного управления эмоциями в стрессовых ситуациях" control={ < Radio / > } label="Развитие стрессоустойчивости - техники эффективного управления эмоциями в стрессовых ситуациях" />
                        <FormControlLabel value="Мастер коммуникации - принципы и методы повышения эффективности в ходе повседневного общения, решения деловых вопросов, переговоров, продаж" control={ < Radio / > } label="Мастер коммуникации - принципы и методы повышения эффективности в ходе повседневного общения, решения деловых вопросов, переговоров, продаж" />
                        <FormControlLabel value="Магия взаимодействия - типология личности и как правильно строить отношения с людьми" control={ < Radio / > } label="Магия взаимодействия - типология личности и как правильно строить отношения с людьми" />
                    </RadioGroup>
                </div>
                <div className="block-input" id="form-block-city">
                    <TextField label="Город" variant="standard" id="form-city" name="city" value={form.city} placeholder="Введите название города" onChange={handleChange} onClick={handleChange}/>
                </div>
                <div className="block-select" id="form-block-metro">
                    <Autocomplete
                        disablePortal
                        id="form-metro"
                        options={metros.map((data) => data.name)}
                        sx={{width: 300}}
                        value={form.metro}
                        onChange={handleChange}
                        renderInput={(params) => <TextField {...params} label="Ближайшая станция метро" onClick={handleChange}/>}/>
                </div>
                {seconds > 0 || minutes > 0 ?
                                            (<p className="sms-text">Повторная отправка кода возможна через {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds} секунд</p>)
                                            :
                                            (<p></p>)
                }
                {seconds == 0 && minutes == 0 && form.click_code ?
                                            (<p onClick={handleSubmit} className="sms-text">Не приходит SMS?</p>)
                                            :
                                            (<p></p>)
                }
                {form.click_code &&
                                        <div className="block-input" id="form-block-code">
                                            <TextField label="Введите код из sms" variant="standard" id="form-code" name="code" onChange={handleChange} onClick={handleChange}/>
                                        </div>
                }
                {!form.click_code ?
                                            (<Button className="sms-button" type="submit" onClick={handleSubmit}>Отправить</Button>)
                                            :
                                                (<></>)
                }
                {form.click_code ?
                                            (<Button className="sms-button" type="submit" onClick={handleSubmitSms}>Готово</Button>)
                                            :
                                                (<></>)
                }
                <p>Отправляя сведения вы даёте согласие на обработку персональных данных.</p>
            </div>
            <Message showModalMessage={showModalMessage} modalMessageText={modalMessageText} handleCloseModalMessage={handleCloseModalMessage}/>
            </>
                                    )
                        }

                        export default Form