import React, { Component, useContext } from 'react';
import LogoBottm from '../../images/logoBottm.png';

const Footer = () => {

    return (
            <div className="footer">
                <img src={LogoBottm} />
                <p>Политика обработки персональных данных на официальном сайте банка</p>
            </div>
            )
}

export default Footer
