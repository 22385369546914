import React, { Component } from 'react';
import logo from '../../images/LogoTop.png';

const Header = () => {
    return (
            <>
                <img src={logo} />
                <h1>Хотим, чтобы Вы оставались с нами на связи, получали самые <br/>выгодные предложения и приятные бонусы первыми</h1>
            </>
            );
}

export default Header