import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import Content from './index/content';
import { BrowserRouter } from 'react-router-dom';
import '../less/style.less';

{
    window.location.href.includes('?eruda=true') ? eruda.init() : "";
}

const content = ReactDOM.createRoot(document.getElementById('content'));
content.render(
        <React.StrictMode>
            <BrowserRouter>     
                <Content />
            </BrowserRouter>
        </React.StrictMode>
        );
