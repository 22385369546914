import React from 'react';

const NotFound = () => {

    const height = window.innerHeight;
    const width = window.innerWidth;

    if (width > 1023) {
        return (
                <div className="block-notFound" style={{height: height-333+'px'}}>
                    <h1>404 - страница не найдена</h1>
                </div>
                )
    }
    if (width < 1023) {
        return (
                <div className="block-notFound" style={{height: height-351+'px'}}>
                    <h1>404 - страница не найдена</h1>
                </div>
                )
    }
}

export default NotFound