import React, { Component, useState, useEffect, useContext, useRef } from 'react';
import {Dialog, DialogContent, Button, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, styled, Paper, AppBar, Tabs, Tab, Box, Typography, Tooltip, TextField } from "@mui/material";
import { Link } from 'react-router-dom';
import Moment from 'moment';
import InputMask from "react-input-mask";
import ReactPlayer from 'react-player';
import { AbonentContext } from './content';
import Header from '../header/header';
import Form from '../form/form';

function Index(props) {

    return (
            <>
            <div className="block-content">
                <div className="header">
                    <Header />
                </div>
                <div className="form">
                    <Form urlServer = {props.urlServer}/>
                </div>
            </div>
            </>
            )
}

export default Index