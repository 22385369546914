import React, {Component} from 'react';
import {Dialog, DialogContent, Button} from "@mui/material";

const Message = (props) => {

    function closeModal() {
        props.handleCloseModalMessage()
    }

    return(
            <Dialog open={ props.showModalMessage ? props.showModalMessage : false } onClose={closeModal} closeAfterTransition fullWidth={true} maxWidth={'xs'} id="message">
                <DialogContent>
                    <div className="block-message">
                        <img className="modal-i-close" src="/images/modal-i-close.png" onClick={closeModal}/>
                        <h1 dangerouslySetInnerHTML={{__html: props.modalMessageTitle ? props.modalMessageTitle : ''}}></h1>
                        <p dangerouslySetInnerHTML={{__html: props.modalMessageText ? props.modalMessageText : ''}}></p>
                    </div>
                </DialogContent>
            </Dialog>
            )
}

export default Message